/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'styles/index.js'
import 'core-js/features/set'
import 'core-js/features/map'
import 'core-js/es/array'

const context = require.context('containers', true)
const ReactRailsUJS = require('react_ujs')

ReactRailsUJS.useContext(context)
